import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const createDispatch = createAsyncThunk('dispatch/createDispatch',
    async (postData) => {
        postData['user'] = 'Cooper';

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/dispatch/`, postData, {
            headers: {
                'Accept': 'application/json'
            }
        });

        return data
    }
)

export const saveDispatch = createAsyncThunk('dispatch/saveDispatch',
    async (postData) => {

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/dispatch/${postData.id}`, postData, {
            headers: {
                'Accept': 'application/json'
            }
        });


        return data
    }
)


export const getDispatchDetails = createAsyncThunk('dispatch/getDispatchDetails',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/dispatch/${sop_order_line_id}`);

        return data
    }
)

export const getDispatchesByOrderLine = createAsyncThunk('dispatch/getDispatchesByOrderLine',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/dispatch/byorderlineid/${sop_order_line_id}`);

        return data
    }
)


const initialSate = {
    loading: false,
    data: {
        id: null,
        courier: null,
        tracking_number: null,
        notes: null,
        sop_order_line_id: null
    },
    lines: []
}

export default createSlice({
    name: 'dispatch',
    initialState: initialSate,
    reducers: {},
    extraReducers: {
        [saveDispatch.pending]: (state) => {
            state.loading = true;
        },
        [saveDispatch.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        [saveDispatch.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },
        [getDispatchDetails.pending]: (state) => {
            state.data = initialSate;
            state.loading = true;
        },
        [getDispatchDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            if (Object.keys(payload).length === 0) {
                state.data = initialSate
            } else {
                state.data = payload;
            }
        },
        [getDispatchDetails.rejected]: (state, action) => {
            console.log(action.error);
            state.data = initialSate;
            state.loading = false;
        },

        [getDispatchesByOrderLine.pending]: (state) => {
            state.lines = [];
            state.loading = true;
        },
        [getDispatchesByOrderLine.fulfilled]: (state, { payload }) => {
            state.loading = false;
            if (Object.keys(payload).length === 0) {
                state.lines = []
            } else {
                state.lines = payload;
            }
        },
        [getDispatchesByOrderLine.rejected]: (state, action) => {
            console.log(action.error);
            state.data = [];
            state.lines = [];
            state.loading = false;
        },


        [createDispatch.pending]: (state) => {
            state.loading = true;
        },
        [createDispatch.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
            state.lines.push(payload);
        },
        [createDispatch.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },

    }
})
