import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

//Log slice
export const getLogs = createAsyncThunk('orderLine/getLog',
    async (sop_order_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/log/${sop_order_id}`);
        return data
    }
)

const initialSate = {
    loading: false,
    logs: []
}

export default createSlice({
    name: 'log',
    initialState: initialSate,
    reducers: {},
    extraReducers: {
        [getLogs.pending]: (state) => {
            state.loading = true;
        },
        [getLogs.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.logs = payload;
        },
        [getLogs.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        }
    }
})

