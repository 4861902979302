import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from "react-toastify";

//Renew refresh token
export const renewRefreshToken = createAsyncThunk('sageOrderLines/renewRefreshToken',
    async (code, { rejectWithValue }) => {


        const params = {
            'code': code,
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sageapi/authenticate`, { params },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)


//Sage order lines slice
export const getAllSageOrderLines = createAsyncThunk('sageOrderLines/getAllSageOrderLines',
    async ({ searchString, orderDate, dispatchDate, beforeDispatchDate, deliveryDate, lineStatus, readyToProceed, sopOrderLineAnalysisCode2, sopOrderLineAnalysisCode4 }, { rejectWithValue }) => {

        const params = {
            'searchString': searchString,
            'orderDate': orderDate,
            'dispatchDate': dispatchDate,
            'beforeDispatchDate': beforeDispatchDate,
            'deliveryDate': deliveryDate,
            'lineStatus': lineStatus,
            'readyToProceed': readyToProceed,
            'sopOrderLineAnalysisCode2': sopOrderLineAnalysisCode2,
            'sopOrderLineAnalysisCode4': sopOrderLineAnalysisCode4,
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sageorderlines`, { params },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const getSageOrderLine = createAsyncThunk('sageOrderLines/getSageOrderLine',
    async (id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/sageorderline/${id}`);
        return data
    }
)

export const updateOrderLine = createAsyncThunk('sageOrderLines/updateOrderLine',
    async ({ id, notes, repeat, samples }, { rejectWithValue }) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/orderline/${id}`, { notes, repeat, samples },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const updateStatus = createAsyncThunk(
    "order/updateStatus",
    async ({ id, type, status }, { rejectWithValue }) => {

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/orderline/${type}/status/`, { id, status },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const logWorksOrderPrinted = createAsyncThunk(
    "order/logWorksOrderPrinted",
    async (id, { rejectWithValue }) => {


        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/order/logworksorderprinted/`, { id },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export default createSlice({
    name: 'sageOrderLines',
    initialState: { loading: false, data: [], selected: {} },
    reducers: {
    },
    extraReducers: {
        [getSageOrderLine.pending]: (state) => {
            state.selected = {};
            state.loading = true;
        },
        [getSageOrderLine.rejected]: (state, { payload }) => {
            console.log(payload);
        },
        [getSageOrderLine.fulfilled]: (state, { payload }) => {
            state.selected = payload[0];
            state.loading = false;
        },
        [getAllSageOrderLines.pending]: (state) => {
            state.data = [];
            state.loading = true;
        },
        [getAllSageOrderLines.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [getAllSageOrderLines.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },

        [updateStatus.fulfilled]: (state, { payload }) => {
            state.selected.status = payload;
            console.log(payload);
        },
        [updateStatus.rejected]: (state, action) => {
            console.log(action);
        },
        [updateOrderLine.fulfilled]: (state, { payload }) => {
            state.selected.status = payload;
            console.log(payload);
        },
        [updateOrderLine.rejected]: (state, action) => {
            console.log(action);
        },
        [logWorksOrderPrinted.fulfilled]: (state, { payload }) => {
            console.log(payload);
        },
        [logWorksOrderPrinted.rejected]: (state, action) => {
            console.log(action);
        },
        [updateOrderLine.rejected]: (state, action) => {
            console.log(action);
        },
        [renewRefreshToken.fulfilled]: (state, { payload }) => {
            console.log(payload);
            toast.success('Refresh Token Updated');
        },
        [renewRefreshToken.rejected]: (state, action) => {
            console.log(action);
            toast.error('Something went wrong');
        }
    }
})