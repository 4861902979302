import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const searchStock = createAsyncThunk('dispatch/searchStock',
    async ({ searchString }) => {

        const params = {
            'search': searchString
        }

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/stock/list/`, { params }, {
            headers: {
                'Accept': 'application/json'
            }
        });

        return data
    }
)

const initialSate = {
    loading: false,
    products: []
}

export default createSlice({
    name: 'stock',
    initialState: initialSate,
    reducers: {},
    extraReducers: {
        [searchStock.pending]: (state) => {
            state.loading = true;
            state.products = [];
        },
        [searchStock.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.products = payload;
        },
        [searchStock.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        }
    }
})
