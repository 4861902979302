import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getOrders = createAsyncThunk(
  "order/getOrders",
  async ({ searchString, orderDate, dispatchDate, deliveryDate }) => {

    //add logged in user ID to post data
    let userId = JSON.parse(localStorage.getItem("user-info")).id;

    const params = {
      searchString: searchString,
      orderDate: orderDate,
      dispatchDate: dispatchDate,
      deliveryDate: deliveryDate,
      userId
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/order/list`,
      { params },
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return data;
  }
);

export const getOrder = createAsyncThunk("order/getOrder", async (orderId) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/order/${orderId}`, {
    headers: {
      Accept: "application/json",
    },
  });
  return data;
});

export const getOrderConfirmation = createAsyncThunk("order/getOrderConfirmation", async (orderId) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/order/confirmation/${orderId}`, {
    headers: {
      Accept: "application/json",
    },
  });
  return data;
});

export const getOrderLines = createAsyncThunk(
  "order/getOrderLines",
  async (orderId) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/order/line/${orderId}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return data;
  }
);

export const sendOrderConfirmation = createAsyncThunk(
  "order/sendOrderConfirmation",
  async (postData, { rejectWithValue }) => {



    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/order/sendconfirmation`,
        postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const approveOrderConfirmation = createAsyncThunk(
  "order/approveOrderConfirmation",
  async (id, { rejectWithValue }) => {

    //add logged in user ID to post data
    let userId = JSON.parse(localStorage.getItem("user-info")).id;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/order/confirmation/approve/${id}`,
        {
          params: { 'userId': userId },
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOrderConfirmation = createAsyncThunk('order/deleteOrderConfirmation',
  async (id, { rejectWithValue }) => {

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/order/confirmation/${id}`,
        {
          headers: {
            'Accept': 'application/json'
          }
        })
      return response.data
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }
  }
)

export const markAsPaid = createAsyncThunk(
  "order/markAsPaid",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/order/paid/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderConfirmations = createAsyncThunk(
  "order/getOrderConfirmations",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/order/confirmation/`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const chaseOrderConfirmations = createAsyncThunk(
  "order/chaseOrderConfirmations",
  async (ids, { rejectWithValue }) => {

    //add logged in user ID to post data
    let userId = JSON.parse(localStorage.getItem("user-info")).id;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/order/confirmation/chase`,
        {
          params: { ids, userId },
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


const initialSate = {
  loading: false,
  orders: [],
  order: {},
  orderLines: [],
  orderConfirmation: {},
  orderConfirmations: [],
};

export default createSlice({
  name: "order",
  initialState: initialSate,
  reducers: {},
  extraReducers: {
    [getOrderConfirmation.pending]: (state) => {
      state.orderConfirmation = {}
    },
    [getOrderConfirmation.fulfilled]: (state, { payload }) => {
      state.orderConfirmation = payload;
    },
    [getOrderConfirmation.rejected]: (state, action) => {
      console.log(action);
    },

    [getOrders.pending]: (state) => {
      state.loading = true;
      state.orders = [];
    },
    [getOrders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orders = payload;
    },
    [getOrders.rejected]: (state, action) => {
      console.log(action);
      state.loading = false;
    },
    [getOrder.pending]: (state) => {
      state.order = {};
      state.loading = true;
    },
    [getOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.order = payload;
    },
    [getOrder.rejected]: (state, action) => {
      console.log(action);
      state.loading = false;
    },
    [getOrderLines.pending]: (state) => {
      state.loading = true;
      state.orderLines = [];
    },
    [getOrderLines.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orderLines = payload;
    },
    [getOrderLines.rejected]: (state, action) => {
      console.log(action);
    },
    [sendOrderConfirmation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      console.log(payload);
      toast.success('Order confirmation sent');
    },
    [sendOrderConfirmation.pending]: (state) => {
      state.loading = true;
    },
    [sendOrderConfirmation.rejected]: (state, action) => {
      console.log(action);
      toast.error('Order confirmation failed to send');
    },
    [approveOrderConfirmation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      console.log(payload);
    },
    [approveOrderConfirmation.pending]: (state) => {
      state.loading = true;
    },
    [approveOrderConfirmation.rejected]: (state, action) => {
      console.log(action);
    },
    [deleteOrderConfirmation.fulfilled]: (state, { payload }) => {
      toast.success('Order confirmation deleted');
      state.loading = false;
    },
    [deleteOrderConfirmation.pending]: (state) => {
      state.loading = true;
    },
    [deleteOrderConfirmation.rejected]: (state, action) => {
      toast.error('Order confirmation not deleted');
      state.loading = false;
    },

    [getOrderConfirmations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orderConfirmations = payload;
    },
    [getOrderConfirmations.pending]: (state) => {
      state.loading = true;
      state.orderConfirmations = [];
    },
    [getOrderConfirmations.rejected]: (state, action) => {
      console.log(action);
    },

    [chaseOrderConfirmations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      console.log(payload);
    },
    [chaseOrderConfirmations.pending]: (state) => {
      state.loading = true;
    },
    [chaseOrderConfirmations.rejected]: (state, action) => {
      console.log(action);
    }



  },
});
