import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

//Quality Control Slice
export const approve = createAsyncThunk('qualitycontrol/approve',
    async (postData, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/qualitycontrol/`, postData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                })
            return response.data
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
        }
    }
)

export const getQualityControl = createAsyncThunk('qualitycontrol/getQualityControl',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/qualitycontrol/${sop_order_line_id}`, {
            headers: {
                'Accept': 'application/json'
            }
        });

        return data
    }
)

// Custom action to dispatch approve and then getQualityControl
export const approveAndRefresh = (postData) => async (dispatch) => {
    const resultAction = await dispatch(approve(postData));
    if (approve.fulfilled.match(resultAction)) {
        dispatch(getQualityControl(postData.sop_line_id));
    }
};

const initialSate = {
    loading: false,
    qualityControl: {}
}

export default createSlice({
    name: 'qualityControl',
    initialState: initialSate,
    reducers: {},
    extraReducers: {
        [approve.pending]: (state) => {
            state.loading = true;
            state.qualityControl = {};
        },
        [approve.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.qualityControl = payload;
        },
        [approve.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },
        [getQualityControl.pending]: (state) => {
            state.loading = true;
            state.qualityControl = {};
        },
        [getQualityControl.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.qualityControl = payload;
        },
        [getQualityControl.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        }
    }
})

