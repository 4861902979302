import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk for uploading a delivery note
export const uploadDeliveryNote = createAsyncThunk(
  "deliveryNote/upload",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/deliverynote/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to upload delivery note");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for fetching delivery notes
export const fetchDeliveryNotes = createAsyncThunk(
  "deliveryNote/fetch",
  async (sop_order_id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/deliverynote/${sop_order_id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch delivery notes");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for deleting a delivery note
export const deleteDeliveryNote = createAsyncThunk(
  "deliveryNote/delete",
  async (noteId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/deliverynote/${noteId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete delivery note");
      }
      return noteId; // Return the deleted note ID
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delivery note slice
export default createSlice({
  name: "deliveryNote",
  initialState: {
    notes: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle uploadDeliveryNote
    builder
      .addCase(uploadDeliveryNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadDeliveryNote.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
      })
      .addCase(uploadDeliveryNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle fetchDeliveryNotes
    builder
      .addCase(fetchDeliveryNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDeliveryNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
      })
      .addCase(fetchDeliveryNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle deleteDeliveryNote
    builder
      .addCase(deleteDeliveryNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDeliveryNote.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = state.notes.filter((note) => note.id !== action.payload);
      })
      .addCase(deleteDeliveryNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
