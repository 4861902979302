export const logoNegative = ['300 35', `
  <title>logo</title>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 300 35" style="enable-background:new 0 0 300 35;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#00A1FF;}
</style>
<g>
 <g>
	 <g>
		 <g>
			 <path class="st0" d="M161.8,7.9h-6.9v21.5h-2.8V7.9h-7V5.3h16.7V7.9z"/>
			 <path class="st0" d="M175.7,21.5h-12.1c0,1.9,0.6,3.4,1.5,4.4s2.3,1.6,4.1,1.6c1.9,0,3.7-0.6,5.3-1.9v2.6
				 c-1.5,1.1-3.5,1.6-6,1.6c-2.4,0-4.3-0.8-5.7-2.3c-1.4-1.6-2.1-3.8-2.1-6.6c0-2.7,0.8-4.8,2.3-6.5s3.4-2.5,5.6-2.5s4,0.7,5.2,2.2
				 s1.8,3.5,1.8,6.1L175.7,21.5L175.7,21.5z M172.8,19.1c0-1.6-0.4-2.8-1.1-3.7c-0.7-0.9-1.8-1.3-3.1-1.3s-2.4,0.5-3.3,1.4
				 c-0.9,0.9-1.5,2.1-1.7,3.6H172.8z"/>
			 <path class="st0" d="M192.1,29.4h-2.8v-2.7h-0.1c-1.2,2.1-3,3.1-5.3,3.1c-1.7,0-3-0.5-4-1.4s-1.5-2.1-1.5-3.6
				 c0-3.2,1.9-5.1,5.7-5.6l5.1-0.7c0-2.9-1.2-4.4-3.5-4.4c-2.1,0-3.9,0.7-5.6,2.1v-2.8c1.7-1.1,3.6-1.6,5.8-1.6
				 c4,0,6.1,2.1,6.1,6.4v11.2H192.1z M189.4,20.7l-4.1,0.6c-1.3,0.2-2.2,0.5-2.9,0.9c-0.6,0.5-1,1.3-1,2.4c0,0.8,0.3,1.5,0.9,2.1
				 c0.6,0.5,1.4,0.8,2.4,0.8c1.4,0,2.5-0.5,3.4-1.4c0.9-1,1.3-2.2,1.3-3.6L189.4,20.7L189.4,20.7z"/>
			 <path class="st0" d="M221.7,29.4h-2.8v-9.9c0-1.9-0.3-3.3-0.9-4.1c-0.6-0.8-1.6-1.3-3-1.3c-1.2,0-2.2,0.5-3,1.6
				 s-1.2,2.4-1.2,3.9v9.8h-2.7V19.2c0-3.4-1.3-5.1-3.9-5.1c-1.2,0-2.2,0.5-3,1.5s-1.2,2.3-1.2,4v9.8h-2.8V12.2h2.8v2.7h0.1
				 c1.2-2.1,3-3.1,5.3-3.1c1.2,0,2.2,0.3,3.1,1s1.5,1.5,1.8,2.6c1.3-2.4,3.2-3.6,5.7-3.6c3.8,0,5.7,2.3,5.7,7L221.7,29.4
				 L221.7,29.4z"/>
		 </g>
	 </g>
 </g>
 <g>
	 <path d="M94.1,28h-5.5l-3.3-5.4c-0.2-0.4-0.5-0.8-0.7-1.1s-0.5-0.6-0.7-0.8c-0.2-0.2-0.5-0.4-0.7-0.5c-0.3-0.1-0.5-0.2-0.8-0.2
		 h-1.3V28h-4.8V6.8h7.6c5.1,0,7.7,1.9,7.7,5.8c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.5,1.2-1,1.7c-0.4,0.5-0.9,0.9-1.5,1.3
		 c-0.6,0.4-1.2,0.6-2,0.9v0.1c0.3,0.1,0.6,0.3,0.9,0.5c0.3,0.2,0.6,0.5,0.9,0.8c0.3,0.3,0.5,0.6,0.8,1c0.3,0.3,0.5,0.7,0.7,1
		 L94.1,28z M81.1,10.4v5.9h2.1c1,0,1.8-0.3,2.5-0.9c0.6-0.6,0.9-1.3,0.9-2.2c0-1.9-1.1-2.8-3.3-2.8H81.1z"/>
	 <path d="M113.3,19.6c0,2.8-0.7,5-2,6.5c-1.3,1.5-3.2,2.3-5.7,2.3c-1.1,0-2.1-0.2-3.1-0.6v-4.5c0.8,0.6,1.7,0.9,2.7,0.9
		 c2.2,0,3.3-1.6,3.3-4.8V6.8h4.8V19.6z"/>
	 <path d="M122.7,20.7V28h-4.8V6.8h7.5c5.3,0,8,2.2,8,6.7c0,2.1-0.8,3.8-2.3,5.2c-1.5,1.3-3.6,2-6.1,2H122.7z M122.7,10.5v6.6h1.9
		 c2.5,0,3.8-1.1,3.8-3.3c0-2.2-1.3-3.3-3.8-3.3H122.7z"/>
 </g>
 <g>
	 <path d="M103.8,17c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.6-0.4s-0.4-0.3-0.5-0.5
		 c-0.2,0.1-0.4,0.3-0.7,0.5c-0.3,0.2-0.5,0.3-0.9,0.4s-0.7,0.2-1.1,0.3S97.9,17,97.4,17c-0.6,0-1.1-0.1-1.6-0.2s-0.9-0.3-1.3-0.6
		 c-0.4-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.4,0.1-0.8,0.2-1.1c0.2-0.3,0.3-0.6,0.6-0.9c0.2-0.2,0.5-0.5,0.8-0.6
		 s0.6-0.3,0.9-0.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.3-0.3-0.4-0.5s-0.2-0.4-0.3-0.6s-0.1-0.4-0.1-0.7c0-0.4,0.1-0.7,0.2-1
		 s0.4-0.6,0.7-0.8s0.6-0.4,1-0.5C97,6.1,97.5,6,97.9,6c0.5,0,0.9,0.1,1.2,0.2c0.4,0.1,0.7,0.3,1,0.5s0.5,0.4,0.6,0.7
		 c0.1,0.3,0.2,0.6,0.2,0.9c0,0.5-0.2,1-0.5,1.4c-0.3,0.4-0.8,0.7-1.4,1c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.1,0.4,0.3,0.6,0.5
		 c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.5,0.6,0.8c0.4-0.5,0.7-1,0.9-1.6c0.2-0.6,0.3-1.2,0.3-1.8c0-0.2,0-0.4,0-0.5
		 s-0.1-0.3-0.1-0.5h2c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3,0,0.5c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.2,0.9-0.4,1.3
		 c-0.2,0.4-0.4,0.8-0.7,1.2c-0.3,0.4-0.5,0.7-0.9,1c0.2,0.3,0.5,0.4,0.7,0.6c0.3,0.1,0.5,0.2,0.7,0.2c0.2,0,0.5,0,0.7-0.1
		 c0.2-0.1,0.5-0.1,0.6-0.2v1.8c-0.3,0.1-0.5,0.2-0.8,0.2S104.1,17,103.8,17z M97.6,15.2c0.3,0,0.6,0,0.8-0.1
		 c0.3-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.3,0.5-0.4c-0.3-0.4-0.5-0.7-0.7-1c-0.2-0.3-0.5-0.5-0.7-0.7
		 c-0.2-0.2-0.5-0.4-0.7-0.5s-0.5-0.3-0.7-0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.2-0.3,0.3-0.3,0.5
		 c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0,0.5,0.1,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.2,0.6,0.3C97.1,15.2,97.3,15.2,97.6,15.2z
		  M98.9,8.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
		 c-0.1,0-0.3,0.1-0.4,0.2C97,8,97,8.1,96.9,8.2c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.3,0.1,0.5,0.3,0.8s0.4,0.4,0.6,0.6
		 c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3s0.2-0.2,0.3-0.4C98.9,8.9,98.9,8.7,98.9,8.5z"/>
 </g>
 <rect x="138.4" y="5.2" width="1.5" height="24.2"/>
</g>
</svg>
`]
