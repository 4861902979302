import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useHistory } from 'react-router';

export const register = createAsyncThunk('user/register',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/register`, params,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const login = createAsyncThunk('user/login',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/login`, params,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const initialSate = {
    loading: false,
    user: {}
}

export default createSlice({
    name: 'user',
    initialState: initialSate,
    reducers: {
        logout: (state) => {
            state.user = null;
        }
    },
    extraReducers: {
        [register.pending]: (state) => {
            state.loading = true;
        },
        [register.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.user = payload;
            localStorage.setItem("user-info", JSON.stringify(payload));
        },
        [register.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },
        [login.pending]: (state) => {
            state.loading = true;
        },
        [login.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.user = payload;
            //state.user.authenticated = true; removed to fix Cannot create property 'authenticated' on string  error
            localStorage.setItem("user-info", JSON.stringify(payload));
        },
        [login.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        }
    }
})
