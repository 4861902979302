import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'


import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cidBrush,
  cisBrush,
  cidFilePowerpoint,
  cidDocument,
  cidBarcode,
  cidBox,
  cidTruck,
  cidImage,
  cidBritishPoundCircle,
  cidInboxIn,
  cidInboxOut,
  cilBarcode,
  cisBarcode,
  cisBook,
  cilFactory,
  cilSettings,
  cilSend,
  cilBritishPound,
  cilUser,
  cilLockLocked,
  cisTrash,
  cisCircle,
  cisSync,
  cisIndustry,
  cisTruck,
  cilBox,
  cibDiscover,
  cisCloudDownload,
  cilBasketArrowDown,
  cisHandPaper,
  cilFile,
  cilTag
} from '@coreui/icons-pro'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cidBrush,
  cisBarcode,
  cisBook,
  cisBrush,
  cidFilePowerpoint,
  cidDocument,
  cidBarcode,
  cidBox,
  cidTruck,
  cidImage,
  cidBritishPoundCircle,
  cidInboxIn,
  cidInboxOut,
  cilBarcode,
  cilFactory,
  cilSettings,
  cilSend,
  cilBritishPound,
  cilUser,
  cilLockLocked,
  cisTrash,
  cisHandPaper,
  cisCircle,
  cisSync,
  cilBox,
  cisTruck,
  cisIndustry,
  cibDiscover,
  cisCloudDownload,
  cilBasketArrowDown,
  cilFile,
  cilTag
}
)
