import { configureStore } from '@reduxjs/toolkit';
import unpackingSlice from './slices/unpacking';
import packingSlice from './slices/packing';
import dispatchSlice from './slices/dispatch';
import printSlice from './slices/print';
import stockSlice from './slices/stock';
import orderSlice from './slices/order';
import coreUi from './slices/coreui';
import artworkProof from './slices/artwork';
import sageOrderLines from './slices/sageorderlines';
import LogSlice from './slices/log';
import qualityControlSlice from './slices/qualitycontrol';
import userSlice from './slices/user';
import statsSlice from './slices/stats';
import purchaseOrderSlice from './slices/purchaseorder';
import quoteSlice from './slices/quote';

//Configure store
export default configureStore({
    reducer: {
        artworkProof: artworkProof.reducer,
        sageOrderLines: sageOrderLines.reducer,
        coreUi: coreUi.reducer,
        dispatch: dispatchSlice.reducer,
        packing: packingSlice.reducer,
        unpacking: unpackingSlice.reducer,
        print: printSlice.reducer,
        stock: stockSlice.reducer,
        order: orderSlice.reducer,
        log: LogSlice.reducer,
        qualityControl: qualityControlSlice.reducer,
        user: userSlice.reducer,
        stats: statsSlice.reducer,
        purchaseOrder: purchaseOrderSlice.reducer,
        quote: quoteSlice.reducer
    }
});