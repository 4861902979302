import React, { Component } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import "./scss/style.scss";

import ProductBox from "./views/warehouse/label/ProductBox";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Print = React.lazy(() => import("./views/orders/lines/print/Jobsheet"));
const Login = React.lazy(() => import("./views/login/Login"));
const Register = React.lazy(() => import('./views/register/Register'));
const EuropaCommercialInvoice = React.lazy(() => import('./views/dispatch/EuropaCommercialInvoice'));
const DispatchStatus = React.lazy(() => import('./views/status-screens/DispatchStatus'));
const Dispatched = React.lazy(() => import('./views/status-screens/Dispatched'));
const MissedOrders = React.lazy(() => import('./views/status-screens/MissedOrders'));
const Picking = React.lazy(() => import('./views/status-screens/Picking'));
const MissingEori = React.lazy(() => import('./views/status-screens/MissingEori'));

//const isAuthenticated = localStorage.getItem("user-info");
const isAuthenticated = localStorage.getItem('user-info');


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>

            <Route
              path="/label/product/box"
              name="Product Box Label"
              render={(props) => <ProductBox {...props} />}
            />

            <Route
              path="/order/line/print/"
              name="Print"
              render={(props) => <Print {...props} />}
            />

            <Route
              path="/statusscreens/dispatch/"
              name="DispatchStatus"
              render={(props) => <DispatchStatus {...props} />}
            />

            <Route
              path="/statusscreens/dispatched/"
              name="Dispatched"
              render={(props) => <Dispatched {...props} />}
            />

            <Route
              path="/statusscreens/picking/"
              name="Picking"
              render={(props) => <Picking {...props} />}
            />

            <Route
              path="/statusscreens/eori/"
              name="Eori"
              render={(props) => <MissingEori {...props} />}
            />

            <Route
              path="/statusscreens/missedorders/"
              name="DispatchStatus"
              render={(props) => <MissedOrders {...props} />}
            />

            <Route
              path="/dispatch/europa-commercial-invoice/"
              name="Europa Commercial Invoice"
              render={(props) => <EuropaCommercialInvoice {...props} />}
            />

            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />

            <Route
              path="/"
              name="Home"
              render={(props) =>
                isAuthenticated ? <TheLayout {...props} /> : <Redirect to="/login" />}
            />

          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
