import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

export const createPrint = createAsyncThunk('print/createPrint',
    async (postData, { rejectWithValue }) => {

        //add logged in user ID to post data
        let userId = JSON.parse(localStorage.getItem("user-info")).id;

        try {
            const response =
                await axios.post(`${process.env.REACT_APP_API_URL}/api/print/`, postData, {
                    headers: {
                        'Accept': 'application/json'
                    }
                });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const savePrint = createAsyncThunk('printing/savePrint',
    async (postData) => {

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/print/${postData.id}`, postData, {
            headers: {
                'Accept': 'application/json'
            }
        });


        return data
    }
)

export const getUnallocatedPrintLines = createAsyncThunk('Print/getUnallocatedPrintLines',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/printallocation/unallocated`);

        return data
    }
)

export const getPrintDetails = createAsyncThunk('Print/getPrintDetails',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/print/${sop_order_line_id}`);

        return data
    }
)

export const getPrintAllocations = createAsyncThunk('Print/getPrintAllocations',
    async (selectedDate) => {
        const formattedDate = selectedDate.toISOString().slice(0, 10);
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/printallocation/dndlist?date=${formattedDate}`)
        return data
    }
)

export const updatePrintAllocation = createAsyncThunk('Print/updatePrintAllocation',
    async (state, { rejectWithValue }) => {

        try {

            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/printallocation/${state.id}`, state, {
                headers: {
                    'Accept': 'application/json'
                }
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }

    })

export const createPrintAllocation = createAsyncThunk('Print/createPrintAllocation',
    async ({ draggable, droppable, selectedDate, qty }, { rejectWithValue, dispatch }) => {

        const formattedDate = selectedDate.toISOString().slice(0, 10);

        const printAllocation =
        {
            sop_order_line_id: draggable.sop_order_line_id,
            print_id: draggable.print_id,
            printer: "test",
            printer_id: droppable.droppableId,
            date: formattedDate,
            qty: qty,
            index: 0
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/printallocation/`, printAllocation, {
                headers: {
                    'Accept': 'application/json'
                }
            });
            dispatch(getUnallocatedPrintLines())
            dispatch(getPrintAllocations(selectedDate));
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }

    })

export const deletePrintAllocation = createAsyncThunk('Print/deletePrintAllocation',
    async ({ draggableId, selectedDate }, thunkAPI) => {
        const print_id = draggableId;
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/printallocation/${print_id}`);
        return thunkAPI.dispatch(getPrintAllocations(selectedDate))
    }
)

export const deletePrint = createAsyncThunk('print/delete',
    async (id, { rejectWithValue }) => {

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/print/${id}`,
                {
                    headers: {
                        'Accept': 'application/json'
                    }
                })
            return response.data
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
        }
    }
)

export const getPrintByOrderLine = createAsyncThunk('Print/getPrintByOrderLine',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/print/byorderlineid/${sop_order_line_id}`);

        return data
    }
)

const initialSate = {
    loading: false,
    data: {
        sop_order_line_id: null,
        method: null,
        silkscreen_mesh_size: null,
        silkscreen_doctor_blade: null,
        tampo_pad_number: null,
        tampo_pad_hardness: null,
        tampo_hits: null,
        notes: null,
    },
    lines: [],
    unallocatedPrints: [],
    allocations: []
}

const printSlice = createSlice({
    name: 'print',
    initialState: initialSate,
    reducers: {
        movePrintAllocation(state, { payload }) {
            console.log(payload);
            state.allocations = payload;
        }
    },
    extraReducers: {

        [getPrintAllocations.pending]: (state) => {
            state.loading = true;
            state.allocations = {}
        },
        [getPrintAllocations.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.allocations = payload;
        },
        [getPrintAllocations.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },

        [getUnallocatedPrintLines.pending]: (state) => {
            state.loading = true;
        },
        [getUnallocatedPrintLines.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.unallocatedPrints = payload;
        },
        [getUnallocatedPrintLines.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },

        [deletePrintAllocation.pending]: (state) => {
            state.loading = true;
        },
        [deletePrintAllocation.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false;
        },
        [deletePrintAllocation.rejected]: (state, { payload }) => {
            console.log(payload);
            state.loading = false;
        },
        [deletePrint.fulfilled]: (state, { payload }) => {
            toast.success('Print option deleted');
            state.loading = false;
        },
        [deletePrint.pending]: (state) => {
            state.loading = true;
        },
        [deletePrint.rejected]: (state, action) => {
            toast.error('Print option not deleted');
            state.loading = false;
        },
        [updatePrintAllocation.pending]: (state) => {
            state.loading = true;
        },
        [updatePrintAllocation.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        [updatePrintAllocation.rejected]: (state, action) => {
            console.log(action);
        },

        [createPrintAllocation.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false;
        },
        [createPrintAllocation.rejected]: (state, action) => {
            console.log(action);
        },

        [savePrint.pending]: (state) => {
            state.loading = true;
        },
        [savePrint.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        [savePrint.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },
        [getPrintDetails.pending]: (state) => {
            state.data = initialSate;
            state.loading = true;
        },
        [getPrintDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            if (Object.keys(payload).length === 0) {
                state.data = initialSate
            } else {
                state.data = payload;
            }
        },
        [getPrintDetails.rejected]: (state, action) => {
            console.log(action.error);
            state.data = initialSate;
            state.loading = false;
        },

        [getPrintByOrderLine.pending]: (state) => {
            state.lines = [];
            state.loading = true;
        },
        [getPrintByOrderLine.fulfilled]: (state, { payload }) => {
            state.loading = false;
            if (Object.keys(payload).length === 0) {
                state.lines = []
            } else {
                state.lines = payload;
            }
        },
        [getPrintByOrderLine.rejected]: (state, action) => {
            console.log(action.error);
            state.data = [];
            state.lines = [];
            state.loading = false;
        },


        [createPrint.pending]: (state) => {
            state.loading = true;
        },
        [createPrint.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
            state.lines.push(payload);
        },
        [createPrint.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },

    }
})

export const { movePrintAllocation } = printSlice.actions;
export default printSlice;

