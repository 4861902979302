import React from "react";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";


function print({ QrCode, productTitle, productCode }) {
    var qty = prompt('How many items per box?');
    var copies = prompt('How many labels?');

    var productTitleArray = productTitle.split(" - ");
    const zpl = `
    ^XA
    ^MMT
    ^PW799
    ^LL1159
    ^LS0
    ^FO450,50
    ^BQN,2,15^FDQA,(01) ${productCode} (37) ${qty}^FS
    ^FT120,1119^A0B,100,99^FH\^FD${productTitleArray[0]}^FS
    ^FT240,1119^A0B,100,99^FH\^FD${productTitleArray[1]}^FS
    ^FT360,1119^A0B,100,99^FH\^FD${productTitleArray[2]}^FS
    ^FT631,1116^A0B,135,134^FH\^FD${productCode}^FS
    ^FT745,1119^A0B,85,84^FH\^FDBox Qty: ${qty}pcs^FS
    ^PQ${copies},0,1,Y^XZ
    `;

    printBarcode(zpl);
}

const printBarcode = async (zpl) => {
    try {
        // Create a new instance of the object
        const browserPrint = new ZebraBrowserPrintWrapper();

        // Select default printer
        const defaultPrinter = await browserPrint.getDefaultPrinter();

        // Set the printer
        browserPrint.setPrinter(defaultPrinter);

        // Check printer status
        const printerStatus = await browserPrint.checkPrinterStatus();

        // Check if the printer is ready
        if (printerStatus.isReadyToPrint) {
            // ZPL script to print a simple barcode

            browserPrint.print(zpl);
        } else {
            console.log("Error/s", printerStatus.errors);
        }
    } catch (error) {
        console.log(error);
        // throw new Error(error);
    }
};

function ProductBox() {
    return (
        <button
            onClick={() => {
                print();
            }}
        >
            Print
        </button>
    )
}

export default print

