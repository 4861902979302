import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const createUnpacking = createAsyncThunk('unpacking/createUnpacking',
    async (postData) => {

        postData.pp = postData.pp === true ? 1 : 0
        postData.screen_wash = postData.screen_wash === true ? 1 : 0
        postData.buffed = postData.buffed === true ? 1 : 0
        postData.issues = postData.issues === true ? 1 : 0


        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/unpacking/`, postData, {
            headers: {
                'Accept': 'application/json'
            }
        });

        if (Object.keys(data).length > 0) {
            data.pp = data.pp === 1 ? true : false
            data.screen_wash = data.screen_wash === 1 ? true : false
            data.buffed = data.buffed === 1 ? true : false
            data.issues = data.issues === 1 ? true : false
        }

        return data
    }
)

export const saveUnpacking = createAsyncThunk('unpacking/saveUnpacking',
    async (postData) => {

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/unpacking/${postData.id}`, postData, {
            headers: {
                'Accept': 'application/json'
            }
        });

        if (Object.keys(data).length > 0) {
            data.pp = data.pp === 1 ? true : false
            data.screen_wash = data.screen_wash === 1 ? true : false
            data.buffed = data.buffed === 1 ? true : false
            data.issues = data.issues === 1 ? true : false
        }

        return data
    }
)

export const getUnpackingDetails = createAsyncThunk('unpacking/getUnpackingDetails',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/unpacking/${sop_order_line_id}`);

        if (Object.keys(data).length > 0) {
            data.pp = data.pp === 1 ? true : false
            data.screen_wash = data.screen_wash === 1 ? true : false
            data.buffed = data.buffed === 1 ? true : false
            data.issues = data.issues === 1 ? true : false
        }

        return data
    }
)


const initialSate = {
    loading: false,
    data: {
        id: null,
        created_at: null,
        updated_at: null,
        user: null,
        qty: null,
        type: null,
        pp: false,
        screen_wash: false,
        buffed: false,
        issues: false,
        notes: null
    }
}

export default createSlice({
    name: 'unpacking',
    initialState: initialSate,
    reducers: {},
    extraReducers: {
        [saveUnpacking.pending]: (state) => {
            state.loading = true;
            state.data = [];
        },
        [saveUnpacking.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        [saveUnpacking.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },


        [getUnpackingDetails.pending]: (state) => {
            state.data = initialSate;
            state.loading = true;
        },
        [getUnpackingDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            if (Object.keys(payload).length === 0) {
                state.data = initialSate
            } else {
                state.data = payload;
            }
        },
        [getUnpackingDetails.rejected]: (state, action) => {
            console.log(action.error);
            state.data = initialSate;
            state.loading = false;
        },


        [createUnpacking.pending]: (state) => {
            state.loading = true;
        },
        [createUnpacking.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        [createUnpacking.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },

    }
})
