import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from "react-toastify";

//Artwork proof slice
export const getArtworkProofs = createAsyncThunk('orderLine/getArtworkProofs',
    async orderLineId => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/artworkproof/byorderlineid/${orderLineId}`);
        return data
    }
)

export const sendArtworkProof = createAsyncThunk('orderLine/sendArtworkProof',
    async (postData, { rejectWithValue }) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/artworkproof/send`, postData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    }
                })
            return response.data
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
        }
    }
)

export const approveProof = createAsyncThunk('artworkProof/approveProof',
    async (postData, { rejectWithValue }) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/artworkproof/approve`, postData,
                {
                    headers: {
                        'Accept': 'application/json'
                    }
                })
            return response.data
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteProof = createAsyncThunk('artworkProof/deleteProof',
    async (id, { rejectWithValue }) => {

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/artworkproof/${id}`,
                {
                    headers: {
                        'Accept': 'application/json'
                    }
                })
            return response.data
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data)
        }
    }
)

export const getUnapprovedProofs = createAsyncThunk(
    "artwork/getUnapprovedProofs",
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/artworkproof/unapprovedprooflist/`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const chaseArtworkProofs = createAsyncThunk(
    "artwork/chaseArtworkProofs",
    async (ids, { rejectWithValue }) => {

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/artworkproof/chase/`,
                {
                    params: { ids },
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export default createSlice({
    name: 'artworkProof',
    initialState: { loading: false, data: [], unapprovedProofsList: [] },
    reducers: {},
    extraReducers: {
        [getUnapprovedProofs.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false;
            state.unapprovedProofsList = payload;
        },
        [getUnapprovedProofs.pending]: (state, { payload }) => {
            state.loading = true;
            state.unapprovedProofsList = [];
        },
        [getUnapprovedProofs.rejected]: (state, action) => {
            console.log(action);
        },

        [getArtworkProofs.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        [getArtworkProofs.pending]: (state, { payload }) => {
            state.loading = true;
            state.data = [];
        },
        [approveProof.rejected]: (state, action) => {
            console.log(action);
            toast.error('Artwork proof failed to update');
        },
        [approveProof.fulfilled]: (state, action) => {
            console.log(action);
            toast.success('Artwork proof approved');
        },
        [sendArtworkProof.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data.push(payload);
            payload.save_only ? toast.success('Artwork proof saved') : toast.success('Artwork proof sent');
        },
        [sendArtworkProof.pending]: (state) => {
            state.loading = true;
        },
        [sendArtworkProof.rejected]: (state, action) => {
            toast.error('Artwork proof failed to send');
            state.loading = false;
        },
        [deleteProof.fulfilled]: (state, { payload }) => {
            toast.success('Artwork proof deleted');
            state.loading = false;
        },
        [deleteProof.pending]: (state) => {
            state.loading = true;
        },
        [deleteProof.rejected]: (state, action) => {
            toast.error('Artwork proof not deleted');
            state.loading = false;
        },
        [chaseArtworkProofs.fulfilled]: (state, { payload }) => {
            state.loading = false;
            console.log(payload);
            toast.success('Artwork proofs have been chased');
        },
        [chaseArtworkProofs.pending]: (state) => {
            state.loading = true;
            toast.info('Please wait');
        },
        [chaseArtworkProofs.rejected]: (state, action) => {
            console.log(action);
            toast.error('An error occurred during chasing');
        }
    }
})