import { createSlice } from '@reduxjs/toolkit';

//CoreUI Slice
const initialState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
}

const coreUi = createSlice({
    name: 'coreUi',
    initialState,
    reducers: {
        sidebarShow: (state, { payload }) => {
            state.sidebarShow = payload
        },
        asideShow: (state, { payload }) => {
            state.asideShow = payload
        },
        darkMode: (state, { payload }) => {
            state.darkMode = payload
        },
    },
})

export const { sidebarShow, asideShow, darkMode } = coreUi.actions

export default coreUi