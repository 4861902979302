import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getStats = createAsyncThunk(
    "stats/getStats",
    async (undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/order/stats`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            return response.data;
        } catch (err) {
            // Use `err.response.data` as `action.payload` for a `rejected` action,
            // by explicitly returning it using the `rejectWithValue()` utility
            return rejectWithValue(err.response.data);
        }
    }
);



export default createSlice({
    name: "stats",
    initialState: {
        loading: false,
        counts: {}
    },
    reducers: {},
    extraReducers: {
        [getStats.pending]: (state) => {
            state.counts = {};
        },
        [getStats.fulfilled]: (state, { payload }) => {
            state.counts = payload;
        },
        [getStats.rejected]: (state, action) => {
            console.log(action);
        },
    },
});
