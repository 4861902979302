import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import log from "./log";

export const getPurchaseOrders = createAsyncThunk(
  "po/getPurchaseOrders",
  async ({ searchString, orderDate, dispatchDate, deliveryDate }) => {
    //add logged in user ID to post data
    let userId = JSON.parse(localStorage.getItem("user-info")).id;

    const params = {
      searchString: searchString,
      userId,
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/purchaseorder/list`,
      { params },
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    return data;
  }
);

export const updatePurchaseOrderLineStatus = createAsyncThunk(
  "purchaseOrderLine/updateStatus",
  async (
    { popOrderReturnId, status, shipName, containerNumber, shippedDate, notes },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/purchase-order-line-status`,
        {
          pop_order_return_line_id: popOrderReturnId,
          ship_name: shipName,
          status,
          container_number: containerNumber,
          shipped_date: shippedDate,
          notes,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//updatePurchaseOrderLineShipment
export const updatePurchaseOrderLineShipment = createAsyncThunk(
  "purchaseOrderLine/updateShipment",
  async (
    {
      shipped_date,
      courier,
      tracking_number,
      ship_name,
      quantity_shipped,
      pop_order_return_line_id,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/purchase-order-line-shipment`,
        {
          pop_order_return_line_id: pop_order_return_line_id,
          quantity_shipped: quantity_shipped,
          courier,
          ship_name: ship_name,
          tracking_number: tracking_number,
          shipped_date: shipped_date,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Get PO shipments
export const getPurchaseOrderShipments = createAsyncThunk(
  "purchaseOrderLine/getShipments",
  async ({ popOrderReturnId }) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/purchase-order-line-shipment/${popOrderReturnId}`
    );
    return data;
  }
);

const initialSate = {
  loading: false,
  purchaseOrders: [],
};

export default createSlice({
  name: "po",
  initialState: initialSate,
  reducers: {},
  extraReducers: {
    [getPurchaseOrders.pending]: (state) => {
      state.loading = true;
      state.purchaseOrders = [];
    },
    [getPurchaseOrders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.purchaseOrders = payload;
    },
    [getPurchaseOrders.rejected]: (state, action) => {
      console.log(action);
      state.loading = false;
    },
    [updatePurchaseOrderLineStatus.pending]: (state) => {
      state.loading = true;
    },
    [updatePurchaseOrderLineStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // Update the specific purchase order line status in the state
      const index = state.purchaseOrders.findIndex(
        (order) =>
          order.pop_order_line_id === payload.data.pop_order_return_line_id
      );

      state.purchaseOrders[index].status = payload.data;
    },
    [updatePurchaseOrderLineStatus.rejected]: (state, action) => {
      console.log(action);
      state.loading = false;
    },
    [updatePurchaseOrderLineShipment.pending]: (state) => {
      state.loading = true;
    },
    [updatePurchaseOrderLineShipment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // Update the specific purchase order line status in the state
      //console.log(payload);
      const index = state.purchaseOrders.findIndex(
        (order) =>
          order.pop_order_line_id === payload.data[0].pop_order_return_line_id
      );

      console.log("index", index);

      state.purchaseOrders[index].shipments = payload.data;
    },
    [updatePurchaseOrderLineShipment.rejected]: (state, action) => {
      console.log(action);
      state.loading = false;
    },
  },
});
