import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const searchQuote = createAsyncThunk('quote/searchQuote',
    async (postData) => {


        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/quotations/search`, postData, {
            headers: {
                'Accept': 'application/json'
            }
        });

        return data
    }
)

const initialSate = {
    loading: false,
    quotes: []
}

export default createSlice({
    name: 'quote',
    initialState: initialSate,
    reducers: {},
    extraReducers: {
        [searchQuote.pending]: (state) => {
            state.loading = true;
            state.quotes = [];
        },
        [searchQuote.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.quotes = payload;
        },
        [searchQuote.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        }
    }
})
