import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const createPacking = createAsyncThunk('packing/createPacking',
    async (postData) => {


        const { data } = await axios.post('${process.env.REACT_APP_API_URL}/api/packing/', postData, {
            headers: {
                'Accept': 'application/json'
            }
        });


        return data
    }
)

export const savePacking = createAsyncThunk('packing/savePacking',
    async (postData) => {

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/packing/${postData.id}`, postData, {
            headers: {
                'Accept': 'application/json'
            }
        });

        if (Object.keys(data).length > 0) {
            data.pp = data.pp === 1 ? true : false
            data.screen_wash = data.screen_wash === 1 ? true : false
            data.buffed = data.buffed === 1 ? true : false
            data.issues = data.issues === 1 ? true : false
        }

        return data
    }
)

export const getPackingDetails = createAsyncThunk('packing/getPackingDetails',
    async (sop_order_line_id) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/packing/${sop_order_line_id}`);

        return data
    }
)


const initialSate = {
    loading: false,
    data: {
        id: null,
        created_at: null,
        updated_at: null,
        user: null,
        type: null,
        packed_in: null,
        qty_received: null,
        reject_reason: null,
        qty_rejected: null,
        notified: null,
        outcome: null,
        notes: null
    }
}

export default createSlice({
    name: 'packing',
    initialState: initialSate,
    reducers: {},
    extraReducers: {
        [savePacking.pending]: (state) => {
            state.loading = true;
        },
        [savePacking.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        [savePacking.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },


        [getPackingDetails.pending]: (state) => {
            state.data = initialSate;
            state.loading = true;
        },

        [getPackingDetails.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.loading = false;
            if (Object.keys(payload).length === 0) {
                state.data = initialSate
            } else {
                state.data = payload;
            }
        },
        [getPackingDetails.rejected]: (state, action) => {
            console.log(action.error);
            state.data = initialSate;
            state.loading = false;
        },


        [createPacking.pending]: (state) => {
            state.loading = true;
        },
        [createPacking.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        },
        [createPacking.rejected]: (state, action) => {
            console.log(action);
            state.loading = false;
        },

    }
})
